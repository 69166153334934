import React from "react";
import "./TuttiHelper.css";
import { useHistory } from "react-router-dom";

const TuttiHelper = () => {
  const history = useHistory();

  const handleBooking = () => {
    history.push("/s?address=Maui%2C%20Hawaii%2C%20USA&bounds=21.03141298976477%2C-155.9790420834986%2C20.57457850828407%2C-156.6969232882344&pub_category=tutti-helper&sort=meta_reviewsAverage"); // Replace with your actual URL
  };
  return (
    <div className="tuttihelper-container">
      {/* First Row: Title Section + Image (Image on the Right) */}
      <div className="tuttihelper-row tuttihelper-first-row">
        <div className="tuttihelper-section tuttihelper-title-section">
          <h1>TUTTI HELPERS FOR VACATION RENTAL GUESTS</h1>
          <div className="tuttihelper-text-section">
            <p>Tutti Helper is a unique service that was custom-tailored to the needs of vacation rental guests. Tutti Helpers are multitaskers, offering a range of services, including tidying the house, babysitting, grocery delivery, laundry, and more. Tutti Helpers are here to elevate your vacation experience by handling the daily tasks that you shouldn’t need to do while on holiday.</p>
          </div>
          {/* <button className="helper-book-now-button" onClick={handleBooking}>BOOK NOW</button> */}
        </div>
        <div className="vertical-divider"></div>

        <div className="tuttihelper-section tuttihelper-image-section">
          <video 
            src="https://res.cloudinary.com/dg1dwlsht/video/upload/v1741724919/shutterstock_1103414921_qpw0zc.mp4" 
            className="tuttihelper-video" 
            autoPlay 
            loop 
            muted 
            playsInline
          />
        </div>
      </div>
      <hr className="line-divider" /> {/* Black line under the video */}


      {/* Second Row: Image on the Left, Text on the Right */}
      {/* <div className="tuttihelper-row tuttihelper-second-row">
        <div className="tuttihelper-section tuttihelper-image-section">
          <img src="https://res.cloudinary.com/dg1dwlsht/image/upload/v1741296142/tuttiHelper_fpomoc.jpg" alt="Babysitting Service" className="tuttihelper-image" />
        </div>
        <div className="tuttihelper-section tuttihelper-title-section">
          <h1>WHAT IS A TUTTI HELPER?</h1>
          <p className="tutti-text">Tutti Helper is a unique service which was custom tailored specifically to the needs of vacation rental guests. Tutti Helpers offer a range of services including babysitting, grocery delivery, tidying the house, laundry and more.</p>
          <p className="tutti-text tutti-text-bald">Tutti helpers are here to elevate your vacation experience by doing the daily tasks that you should't need to do while on a holiday.</p>

        </div>
      </div> */}
    </div>
  );
};

export default TuttiHelper;
