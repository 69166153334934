import React from "react";
import { useHistory } from "react-router-dom";
import "./Babysitter.css";

const Babysitters = () => {
  const history = useHistory();

  const handleBooking = () => {
    history.push("/s?address=Maui%2C%20Hawaii%2C%20USA&bounds=21.03141298976477%2C-155.9790420834986%2C20.57457850828407%2C-156.6969232882344&pub_category=babysitter&sort=meta_reviewsAverage"); // Replace with your actual URL
  };

  return (
    <div className="babysitters-container">
      {/* First Row: Title Section + Image */}
      <div className="babysitters-row first-row">
        <div className="babysitters-section title-section">
          <h2>TUTTI BABYSITTERS & CHILDCARE</h2>
          <p>Designated for traveling families</p>
          <p>Freedom to choose your own babysitter</p>
          <p>Open communication</p>
          <p>No subscription</p>
          <p>24-hour free cancellation</p>
          <p>Special needs care</p>

          {/* BOOK NOW Button */}
          <button className="book-now-button" onClick={handleBooking}>BOOK NOW</button>
        </div>
        <div className="vertical-divider"></div>
        <div className="babysitters-section image-section-1">
          <video 
            src="https://res.cloudinary.com/dg1dwlsht/video/upload/v1741725639/shutterstock_1056581264_1_eckuze.mp4" 
            className="babysitter-video" 
            autoPlay 
            loop 
            muted 
            playsInline
          />
        </div>
      </div>
      <hr className="line-divider" /> {/* Black line under the video */}


      {/* Second Row: Two Images Side by Side */}
      <div className="babysitters-row second-row">
        <div className="babysitters-section image-section">
          <img src="https://res.cloudinary.com/dg1dwlsht/image/upload/v1741898998/tuthelp_okscer.jpg" alt="Beach Babysitting" className="babysitter-image" />
        </div>
        <div className="vertical-divider"></div>
        <div className="babysitters-section stacked-content">
          <img src="https://res.cloudinary.com/dg1dwlsht/image/upload/v1741296139/camera_atsbvs.png" alt="Playground Babysitting" className="stacked-image top-image" />
          <h2 className="stacked-title">STEP INTO THE FUTURE</h2>
            <p className="stacked-text">
            At Tutti, our babysitters are carefully verified and specialize in working with traveling families. We understand that simply being “verified” doesn’t automatically ensure trust. That’s why many Tutti babysitters come with the option to include a camera, allowing you the option to monitor their care in real-time. With full transparency, you can enjoy your day stress-free while vacationing, knowing your child is in safe hands.
            </p>
            <p className="stacked-text">Look for the camera icon beside babysitter profiles.</p>
          <img src="https://res.cloudinary.com/dg1dwlsht/image/upload/v1741296134/icons_cxjemv.png" alt="Evening Babysitting" className="stacked-image bottom-image" />
        </div>
      </div>
      <hr className="line-divider" /> {/* Black line under the video */}
    </div>
  );
};

export default Babysitters;
