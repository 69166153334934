import React from 'react';
import loadable from '@loadable/component';

import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { camelize } from '../../util/string';
import { propTypes } from '../../util/types';

import FallbackPage from './FallbackPage';
import { ASSET_NAME } from './LandingPage.duck';
import TwoSteps from './LandingPageSections/2Steps/2Steps';
import Babysitters from './LandingPageSections/Babysitter/Babysitter';
import TuttiHelper from './LandingPageSections/TuttiHelper/TuttiHelper';
import Providers from './LandingPageSections/Providers/Providers';
import Testimonial from '../PageBuilder/Testimonial/Testimonial';
import PhoneLink from '../PageBuilder/PhoneLink/PhoneLink';
import BusinessLinks from '../PageBuilder/BusinessLinks/BusinessLinks';
import LandingVideo from './LandingPageSections/Video/LandingVideo';
import LandingImage from './LandingPageSections/LandingPagePic/LandingPagePic';

const PageBuilder = loadable(() =>
  import(/* webpackChunkName: "PageBuilder" */ '../PageBuilder/PageBuilder')
);

export const LandingPageComponent = props => {
  const { pageAssetsData, inProgress, error } = props;

  const children = 
  <>
    {/* <LandingImage></LandingImage> */}
    <LandingVideo></LandingVideo>
    <TwoSteps></TwoSteps>
    <Babysitters></Babysitters>
    <TuttiHelper></TuttiHelper>
    <Providers></Providers>
    <Testimonial></Testimonial>
    <BusinessLinks></BusinessLinks>
  </>

  return (
    <>
      <PageBuilder
        pageAssetsData={pageAssetsData?.[camelize(ASSET_NAME)]?.data}
        inProgress={inProgress}
        error={error}
        fa
        llbackPage={<FallbackPage error={error} />}
        children = {children}
      />
    </>
  );
};

LandingPageComponent.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
  error: propTypes.error,
};

const mapStateToProps = state => {
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};
  return { pageAssetsData, inProgress, error };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(connect(mapStateToProps))(LandingPageComponent);

export default LandingPage;
