import React from "react";
import { useHistory } from "react-router-dom";
import "./Providers.css";

const providers = [
  {
    id: 1,
    name: "Samson",
    service: "Coconut Delivery",
    image: "https://res.cloudinary.com/dg1dwlsht/image/upload/v1741296142/samson_uhj2jk.jpg",
    review: 5.0,
    url:'/l/coconut-delivery/66e5f9cf-6321-499b-bf9b-0bff441aaffe',
    price: 75
  },
  {
    id: 2,
    name: "Nitzan",
    service: "Surf Concierge",
    image: "https://res.cloudinary.com/dg1dwlsht/image/upload/v1741296140/nitzan_vktrq1.jpg",
    review: null,
    url:'/l/water-sport-concierge/66ecb098-5edf-4da3-bc1a-114cc3ad69ce',
    price: 100
  },
  {
    id: 3,
    name: "Erin",
    service: "Massage Therapy",
    image: "https://res.cloudinary.com/dg1dwlsht/image/upload/v1741296132/erin_klds4l.jpg",
    review: null, // No review available
    url:'/l/mobile-massage-therapy/66d636de-553c-4e49-999e-25470c04526a',
    price: 145
  },
  {
    id: 4,
    name: "Tamar",
    service: "Personal Shopper",
    image: "https://res.cloudinary.com/dg1dwlsht/image/upload/v1741296141/tamar_bmtunw.jpg",
    review: 5.0,
    url:'/l/personalized-shopping-experience/66c949e2-ade5-4034-8df7-080cda54ebe6', 
    price: 80
  },
  {
    id: 5,
    name: "Yoav",
    service: "Surf & Paddle Board Delivery",
    image: "https://res.cloudinary.com/dg1dwlsht/image/upload/v1741296143/yoav_pjimum.jpg",
    review: null,
    url:'/l/surfboard-and-paddle-board-rentals/6614c8ac-d4a3-4472-9196-2eb382deb180',
    price: 45
  },
  {
    id: 6,
    name: "Courtney",
    service: "Babysitter",
    image: "https://res.cloudinary.com/dg1dwlsht/image/upload/v1741296135/courtny_uwqvgr.jpg",
    review: null,
    url:'/l/babysitting-nannying-vacation-recommendations/67592892-fe00-4bbd-91eb-01ac8823851f',
    price: 35
  },
  {
    id: 7,
    name: "Juli",
    service: "Private Henna Session",
    image: "https://res.cloudinary.com/dg1dwlsht/image/upload/v1741296137/juli_akoekq.jpg",
    review: null,
    url:'/l/babysitter/66e4b0da-64e7-4045-acdf-8fee7decf6f9',
    price: 150
  },
  {
    id: 8,
    name: "Betsy",
    service: "Tutti Helper",
    image: "https://res.cloudinary.com/dg1dwlsht/image/upload/v1742001698/betsy_o0krbc.jpg",
    review: null,
    url:'/l/tutti-helper/675226b3-c138-46e4-b24f-582908ca5077',
    price: 40
  },
  {
    id: 9,
    name: "Lynzy",
    service: "Hair & Make Up",
    image: "https://res.cloudinary.com/dg1dwlsht/image/upload/v1741296138/lynzy_d7zfwc.jpg",
    review: 5.0,
    url:'/l/bridal-hair-makeup/675c974e-5e4a-4618-b1f1-530e311ab581',
    price: 350
  },
  {
    id: 10,
    name: "Hiram",
    service: "Private Chef",
    image: "https://res.cloudinary.com/dg1dwlsht/image/upload/v1741296134/hiram_npxkc0.jpg",
    review: null,
    url:'/l/private-chef/6657902b-5932-4211-9b0c-a54d4ab15c86',
    price: 1350
  },
  {
    id: 11,
    name: "Bridget",
    service: "Babysitter",
    image: "https://res.cloudinary.com/dg1dwlsht/image/upload/v1741296134/bridget_yce8g7.jpg",
    url:'/l/childcare/667ccf29-fdd3-4871-9c68-9b5a95947d09',
    price: 25,
    review: 5.0,
  },
  {
    id: 12,
    name: "Kendali",
    service: "Tutti Helper",
    image: "https://res.cloudinary.com/dg1dwlsht/image/upload/v1742001703/dolly2_yeynq4.jpg",
    review: 5.0, // No review available
    url:'/l/helper/6782f371-96ba-4ca7-8ed1-02e140733303',
    price: 45
  },
  {
    id: 13,
    name: "Mia",
    service: "Babysitter",
    image: "https://res.cloudinary.com/dg1dwlsht/image/upload/v1742001697/mia2_xfv29p.jpg",
    review: null,
    url:'/l/professional-childcare-nanny-babysitter/677a5257-fb83-43d1-930a-334ea30388e2',
    price: 35
  },
  {
    id: 14,
    name: "Sailor",
    service: "Birthday Cakes & Treats",
    image: "https://res.cloudinary.com/dg1dwlsht/image/upload/v1741296134/cake_htvtt1.jpg",
    review: 5.0, // No review available
    url:'/l/home-baker/6614b4f4-6089-4cb5-b4ad-7a4e467bd7b3',
    price: 150
  },
  {
    id: 15,
    name: "Kaycee",
    service: "Tea Ceremony",
    image: "https://res.cloudinary.com/dg1dwlsht/image/upload/v1741296136/kaycee_zf7fv5.jpg",
    review: null,
    url:'/l/tea-ceremony/665a2784-b704-4c0a-93e2-12065b6241ea',
    price: 250
  },
  {
    id: 16,
    name: "Valentina",
    service: "Babysitter",
    image: "https://res.cloudinary.com/dg1dwlsht/image/upload/v1742001697/valentina_hxhyh3.jpg",
    review: 5,
    url:'/l/babysitting/67b254d3-b043-4c72-aebb-0bef873dc9d1',
    price: 25
  },
  {
    id: 17,
    name: "Marcelle",
    service: "Babysitter",
    image: "https://res.cloudinary.com/dg1dwlsht/image/upload/v1741296138/marcelle_y5yrko.jpg",
    review: null,
    url:'/l/nanny/66443adc-e400-4af2-85c5-faf9c0de842c',
    price: 35
  },
  {
    id: 18,
    name: "Mahina",
    service: "Baby Supply Rentals",
    image: "https://res.cloudinary.com/dg1dwlsht/image/upload/v1741296134/baby_i2iamq.jpg",
    review: 5.0,
    url:'/l/pack-play-high-chair-and-baby-bath/6660d669-160a-4952-a1ae-76445a35e98c',
    price: 10
  },
  {
    id: 19,
    name: "Bridget",
    service: "Tutti Helper",
    image: "https://res.cloudinary.com/dg1dwlsht/image/upload/v1742002758/IMG_2855_emwyki.jpg",
    review: 5.0,
    url:'/l/sweet-treats/66134e0a-1cea-4f7f-9ad5-e57df6cabced',
    price: 33
  },
  {
    id: 20,
    name: "Daniel",
    service: "Private Chef",
    image: "https://res.cloudinary.com/dg1dwlsht/image/upload/v1741296135/daniel_nivv8e.jpg",
    review: null,
    url:'/l/elite-maui-chef/6619f52d-04bf-479a-ae43-38e863322b03',
    price: 1350
  },
  {
    id: 21,
    name: "Ruby",
    service: "Yoga Classes",
    image: "https://res.cloudinary.com/dg1dwlsht/image/upload/v1741296140/ruby_jxqjsj.jpg",
    review: null,
    url:'/l/rising-worth-hands-on-private-yoga-session/664bc6d4-4158-4aef-bc4a-748dc5a96cd1',
    price: 150
  },
  {
    id: 22,
    name: "Tana",
    service: "Babysitter",
    image: "https://res.cloudinary.com/dg1dwlsht/image/upload/v1741296142/tana_re5ynu.jpg",
    url:'/l/nanny/671893a2-55cb-4c0c-8e52-4b2e209e7977',
    price: 30,
    review: 5.0,
  },
  {
    id: 23,
    name: "Kendali",
    service: "Babysitter",
    image: "https://res.cloudinary.com/dg1dwlsht/image/upload/v1742001697/dolly1_ylri9h.jpg",
    review: 5.0, // No review available
    url:'/l/babysitter-nannying/6611ba73-9f75-445d-a022-a41d63f3d4a3',
    price: 35
  },
  {
    id: 24,
    name: "Sailor",
    service: "Sweet Treats",
    image: "https://res.cloudinary.com/dg1dwlsht/image/upload/v1741296141/sailor_u5hkk3.jpg",
    review: 5.0,
    url:'/l/sweet-treats/66134e0a-1cea-4f7f-9ad5-e57df6cabced',
    price: 300
  },
  {
    id: 25,
    name: "Saray",
    service: "Photographer",
    image: "https://res.cloudinary.com/dg1dwlsht/image/upload/v1741296141/saray_hf5a50.jpg",
    review: null,
    url:'/l/photographer/66c4deb0-4283-4bc5-aec0-48d27fe333d6',
    price: 545
  },
  {
    id: 26,
    name: "Miri",
    service: "Massage Therapy",
    image: "https://res.cloudinary.com/dg1dwlsht/image/upload/v1741296138/miri_jtqyim.jpg",
    review: 5.0,
    url:'/l/massage-therapy/6610b48f-23b8-471e-8063-32e5f7bc7e65',
    price: 160
  },
  {
    id: 27,
    name: "Lani",
    service: "Massage Therapy",
    image: "https://res.cloudinary.com/dg1dwlsht/image/upload/v1741296137/lani_hhqhnj.jpg",
    review: 5.0,
    url:'/l/massage-personalized-therapeutic-massage/664e97b1-7c98-40da-bbc1-c4650be620f3',
    price: 140
  },
  {
    id: 28,
    name: "Emily",
    service: "Fresh Produce",
    image: "https://res.cloudinary.com/dg1dwlsht/image/upload/v1741296133/fresh_zqpr9n.jpg",
    review: null,
    url:'/l/seasonal-produce-delivery-from-an-upcountry-farm/67461f90-cad4-4741-b432-35c6a4904bad',
    price: 50
  },
  {
    id: 29,
    name: "Sierra",
    service: "Photography",
    image: "https://res.cloudinary.com/dg1dwlsht/image/upload/v1742001698/sierra_hjlnss.jpg",
    review: null,
    url:'/l/professional-photographer/668ff620-80b9-494f-9916-47b3e284b26f',
    price: 700
  },
  {
    id: 30,
    name: "Nohea",
    service: "Vacation Coordinator",
    image: "https://res.cloudinary.com/dg1dwlsht/image/upload/v1742002760/IMG_2854_oonnpc.jpg",
    review: null,
    url:'/l/concierge-services/6611c27e-042f-4b81-807e-1b7c2775a63d',
    price: 100
  },
  {
    id: 31,
    name: "Mia",
    service: "Kids Arts & Crafts",
    image: "https://res.cloudinary.com/dg1dwlsht/image/upload/v1742001697/mia_tmxcry.jpg",
    review: null,
    url:'/l/maui-activities-for-kids/67bed567-c798-41f4-9fb6-bd7664d81a80',
    price: 75
  },
  {
    id: 32,
    name: "Pninit",
    service: "Fresh Baked Goods",
    image: "https://res.cloudinary.com/dg1dwlsht/image/upload/v1742001698/pninit_q4lkh9.jpg",
    review: 5,
    url:'/l/farm-sourced-baked-goodies/66c3d789-1fa6-4e32-939a-9bdb2111cebb',
    price: 100
  },
  {
    id: 33,
    name: "Luciana",
    service: "Tutti Helper",
    image: "https://res.cloudinary.com/dg1dwlsht/image/upload/v1743457285/IMG_3721_utu2eg.jpg",
    review: null,
    url:'/l/tutti-helper/67e7066d-ffb1-424f-950b-98c3f605adf5',
    price: 35
  },
  {
    id: 34,
    name: "Mia",
    service: "Concierge",
    image: "https://res.cloudinary.com/dg1dwlsht/image/upload/v1743544777/IMG_3751_ehzcfm.jpg",
    review: null,
    url:'/l/maui-concierge-services/67bced7b-c71e-4141-b026-6f794a924d4c',
    price: 40
  },
  {
    id: 35,
    name: "Klil",
    service: "Charcuterie Board",
    image: "https://res.cloudinary.com/dg1dwlsht/image/upload/v1743457294/IMG_3722_bh7lxq.jpg",
    review: null,
    url:'/l/charcuterie-board/67ddcd5d-0708-4d2e-b0d9-6c3e142f6418',
    price: 420
  },
  {
    id: 36,
    name: "Kim",
    service: "Goat Yoga",
    image: "https://res.cloudinary.com/dg1dwlsht/image/upload/v1743457596/Screenshot_2025-03-31_at_11.42.57_AM_jg1krx.png",
    review: null,
    url:'/l/lahaina-goat-yoga-price-is-per-person/67622a8d-748a-485b-8811-4e2070151393',
    price: 69
  },
  
];

const Providers = () => {
    const history = useHistory();
  
    const handleCardClick = (url) => {
      history.push(url);
    };

    const handleSeeMoreClick = () => {
      history.push("/s?sort=meta_reviewsAverage&address=Maui%2C%20Hawaii%2C%20USA&bounds=21.03141298976477,-155.9790420834986,20.57457850828407,-156.6969232882344"); // Replace with your actual URL
    };
  
    return (
      <div className="providers-container">
        <h1>From complex to simple. From a stranger to familiar. From visiting to being. </h1>
        <h2>Tutti welcomes you to the Maui community, here you can find the best people to help you with every need. </h2>
        <div className="providers-grid">
          {providers.map((provider) => (
            <div
              key={provider.id}
              className="provider-card"
              onClick={() => handleCardClick(provider.url)}
            >
              <img src={provider.image} alt={provider.name} className="provider-image" />
              <div className="provider-info">
              <div className="provider-top-line">
                <h3 className="provider-name">{provider.name}</h3>
                {provider.review !== null && (
                    <p className="provider-review">{provider.review} ⭐</p>
                  )}
                  </div>
                <div className="provider-details">
                  <p className="provider-service">
                    {provider.service}
                    <span className="provider-price"> ${provider.price}</span>
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <button className="see-more-button" onClick={handleSeeMoreClick}>
          See More
        </button>
      </div>
    );
  };
  
  export default Providers;