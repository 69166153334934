import React from "react";
import "./LandingVideo.css";

const LandingVideo = () => {
  return (
    <div className="video">
      <video className="tuttihelper-video-1" autoPlay loop muted playsInline>
        <source
          src="https://res.cloudinary.com/dg1dwlsht/video/upload/v1743454146/shutterstock_1057932436_1_q4zvjs_f5wiya.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <div className="video-overlay-text">
      Getting Help While You Travel Has Never Been Easier
      </div>
    </div>
  );
};

export default LandingVideo;
