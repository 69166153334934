import React from "react";
import "./2Steps.css";
import Search from "../../../PageBuilder/Serach/Search";
import GeneralSearchPopup from "../../../PageBuilder/GeneralSearch/GeneralSearchPopUp";

const TwoSteps = () => {
  return (
    <div>
    <div className="two-steps-container">
      <div className="search-bar-two-steps">
        <Search />
        <GeneralSearchPopup/>
      </div>
      <div className="steps">
        <div className="step">
          <h3>1. INQUIRE</h3>
          <p>
          Find the right service provider for you. Communicate and send inquiry.
          </p>
        </div>
        <div className="step">
          <h3>2. MAKE A BOOKING</h3>
          <p>
            Make a booking, pay with your credit card in our secure system. 
          </p>
        </div>
      </div>
      <div className="contact-info">
        <p>
          With Tutti you have 24 hour customer service. Tutti concierge is here for you!
        </p>
        <p>
          <a href="mailto:info@tuttivacation.com">info@tuttivacation.com</a>
        </p>
        <p>
          <a href="tel:8082980235">808-298-0235</a>
        </p>
      </div>
    </div>
    <hr className="line-divider" /> {/* Black line under the video */}
    </div>
  );
};

export default TwoSteps;
